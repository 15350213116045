<template>
    <v-row>
        <v-col cols="5">
            <v-card elevation="4">
                <v-card-title>
                    {{ availableStores.length }} Negozi Disponibili
                </v-card-title>
                <v-text-field
                    v-model="availablesearch"
                    label="Ricerca Punto Vendita"
                    append-icon="mdi-magnify"
                    class="mx-4"
                    filled
                ></v-text-field>

                <StoresDragList
                    :stores.sync="availableStores"
                    :search="availablesearch"
                    @currentSelection="availableChanged"
                    :height="'335'"
                ></StoresDragList>
            </v-card>
        </v-col>
        <v-col cols="2" align-self="center">
            <v-row>
                <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                        fab
                        icon
                        large
                        @click="removeAll"
                        :disabled="currentSelected.length === 0"
                    >
                        <v-icon x-large>mdi-rewind</v-icon></v-btn
                    >
                </v-col>
                <v-col
                    cols="12"
                    class="d-flex justify-center"
                    :disabled="currentAvailable.length === 0"
                >
                    <v-btn fab icon large @click="addAll">
                        <v-icon x-large>mdi-fast-forward</v-icon></v-btn
                    >
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="5" :class="{ changed: changed }">
            <v-card elevation="4">
                <v-card-title>
                    {{ promoStores.length }} Negozi Applicati
                </v-card-title>
                <v-text-field
                    v-model="appliedsearch"
                    label="Ricerca Punto Vendita"
                    append-icon="mdi-magnify"
                    class="mx-4"
                    filled
                ></v-text-field>

                <StoresDragList
                    :stores.sync="promoStores"
                    :search="appliedsearch"
                    @currentSelection="appliedChanged"
                    :height="'335'"
                ></StoresDragList>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as storeActions from "../../../store/actions/store";

export const StoresDragList = () =>
    import("../../../components/promo/PromoStoresDragList");

export default {
    // props: ['farmId', 'stores'],

    props: {
        farmId: {
            type: String,
            required: false,
            default: null
        },
        stores: {
            type: Array,
            required: false
        },
        farms: {
            type: Array,
            required: false,
            default: null
        }
    },

    data: () => ({
        promoStores: [],
        availableStores: [],
        appliedsearch: "",
        availablesearch: "",
        mounted: false,
        changed: false,
        currentAvailable: [],
        currentSelected: []
    }),

    components: {
        StoresDragList
    },

    watch: {
        promoStores: function(newVals, oldVals) {
            if (this.mounted) {
                // console.log(newVals, oldVals);
                const stores = newVals.map(val => {
                    return val.IDCliente;
                });
                // console.log(stores)
                this.$emit("update:stores", stores);
            }
        },
        farms: async function(newVal, oldVal) {
            // console.log("farms", newVal.length);
            var p = [];
            for (let i = 0; i < newVal.length; i++) {
                console.log(newVal[i].IDConsorzio);
                const stores = await this.fetchStores(newVal[i].IDConsorzio);
                p = p.concat(stores);
            }
            // console.log(p.length);
            this.availableStores = p.filter(store => {
                return store.CodiceNazionaleCoopGLN.length > 0;
            });
            this.promoStores = [];
        }
    },

    computed: {
        ...mapState({
            farmStores: state => state.storeModule.stores,
            loaded: state => state.storeModule.loaded
        })

        // dragOptions() {
        //     return {
        //         animation: 0,
        //         group: "description",
        //         disabled: !this.editable,
        //         ghostClass: "ghost"
        //     };
        // }
    },

    methods: {
        ...mapActions({
            loadStores: storeActions.STORE_GET_STORES,
            fetchStores: storeActions.STORE_FETCH_STORES
        }),

        async getStores(farmId) {
            try {
                if (!this.loaded) {
                    const state = await this.loadStores(farmId);
                }
                return this.farmStores;
            } catch (err) {
                console.error(err);
            }
        },

        availableChanged(available) {
            //console.log('siamo filtrati e siamo', available)
            this.currentAvailable = available;
        },

        appliedChanged(applied) {
            console.log("siamo filtrati e siamo", applied.length);
            this.currentSelected = applied;
        },

        addAll() {
            //console.log('premuto add all', this.currentAvailable.length)
            this.promoStores = [...this.promoStores, ...this.currentAvailable];
            const ids = this.currentAvailable.map(x => x.IDCliente);
            this.availableStores = this.availableStores.filter(store => {
                return !ids.includes(store.IDCliente);
            });
        },

        removeAll() {
            //console.log('premuto remove all')
            this.availableStores = [
                ...this.availableStores,
                ...this.currentSelected
            ];
            const ids = this.currentSelected.map(x => x.IDCliente);
            this.promoStores = this.promoStores.filter(store => {
                return !ids.includes(store.IDCliente);
            });
        },

        // async convertStores() {
        //     this.mounted = false;
        //     const stores = await this.getStores(this.farmId);
        //     this.availableStores = stores.filter(store => {
        //         return store.CodiceNazionaleCoopGLN.length > 0
        //     });

        //     console.log(this.availableStores)

        //     setTimeout(() => {
        //         this.mounted = true;
        //     }, 0);
        // },

        async convertStores() {
            // console.log(this.farms)
            if (this.farmId === null) {
                // console.log(
                //     "siamo nella situazione multi",
                //     this.farmId,
                //     this.farms
                // );
                var p = [];
                for (let i = 0; i < this.farms.length; i++) {
                    console.log(this.farms[i].IDConsorzio);
                    const stores = await this.fetchStores(
                        this.farms[i].IDConsorzio
                    );
                    p = p.concat(stores);
                }
                // console.log(p.length);
                this.availableStores = p.filter(store => {
                    return store.CodiceNazionaleCoopGLN.length > 0;
                });
                this.promoStores = [];
                this.$emit("update:stores", this.stores);
                setTimeout(() => {
                    this.mounted = true;
                }, 0);
            } else {
                this.mounted = false;
                const farmStores = await this.getStores(this.farmId);
                this.promoStores = farmStores.filter(store => {
                    return (
                        this.stores.includes(store.IDCliente) &&
                        store.CodiceNazionaleCoopGLN.length > 0
                    );
                });
                this.availableStores = farmStores.filter(store => {
                    return (
                        store.CodiceNazionaleCoopGLN.length > 0 &&
                        !this.stores.includes(store.IDCliente)
                    );
                });
                this.$emit("update:stores", this.stores);
                setTimeout(() => {
                    this.mounted = true;
                }, 0);
            }
        }
    },

    mounted() {
        this.$nextTick(async () => {
            await this.convertStores();
        });
    }
};
</script>

<style></style>
